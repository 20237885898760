<!--
    组件说明:
           propertys:    url(必须）上传地址
                         exts(可选) 限定文件类型['xls','txt']
                         maxsize(可选) 最大大小(MB)
           events:      change 文件被选中
                        done   上传完成
           slot:        default: 内嵌组件
                        message: 警告信息
-->
<template>
    <el-form label-width="140px">
        <el-form-item size="large">
            <el-upload
                    action=""
                    ref="upload"
                    :limit="1"
                    :on-change="fileChange"
                    :on-remove="fileRemove"
                    :auto-upload="false">
                <el-button slot="trigger" size="medium" type="primary">选取文件</el-button>
                <el-button style="margin-left: 10px;" size="medium" type="success" @click="submitUpload">上传到服务器</el-button>
                <slot></slot>
                <div v-if="exts.length>0" slot="tip" class="el-upload__tip" style="line-height: 1em">
                    <p>只能上传{{exts.join('、')}}文件，且不超过{{maxsize}}M</p>
                    <p style="color: red;font-size: 20px"><slot name="message"></slot></p>
                </div>
            </el-upload>
        </el-form-item>
    </el-form>


</template>

<script>
    export default {
        data(){
            return {
                file: null,
            }
        },
        props:{
            url:{
                type: String,
                required: true,
            },
            exts:{
                type: Array,
                default: function () {
                    return ['xls','xlsx'];
                },
            },
            maxsize:{
                type: Number,
                default: 5  //不超过5M
            }
        },
        methods:{
            // 文件改变时
            fileChange(file) {
                this.file = file;
                this.checkUpload();
                this.$emit('change', file);
            },
            fileRemove(file) {
                this.file = null;
                this.$emit('change', null);
            },
            checkUpload(){
                if(!this.file){
                    this.$message.warning('请选择要上传的文件！')
                    return false
                }
                const fileName = this.file.name;
                const arr = fileName.split('.');
                const extension = arr[arr.length-1];
                if(!this.exts.includes(extension)){
                    this.$message.warning(`上传模板只能是 ${this.exts.join('、')}格式!`)
                    return false
                }
                const isLt5M = this.file.size / 1024 / 1024 < this.maxsize
                if (!isLt5M) {
                    this.$message.warning(`上传模板大小不能超过 ${this.maxsize}MB!`)
                    return false
                }
                return true;
            },
            submitUpload() {
                if(!this.checkUpload()) return false
                    let fileFormData = new FormData();
                    fileFormData.append('file', this.file.raw);
                    let requestConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            timeout: 20000,
                        },
                    }
                    //无论成功失败清空列表
                    this.$refs.upload.clearFiles()
                    this.file = null
                    this.$emit('change', null)
                    this.$axios.post(this.url, fileFormData, requestConfig).then(() => {
                        this.$message({
                            message: '文件上传成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.$emit('done')
                            }
                        })
                    })
            },
        }
    }
</script>

<style scoped>

</style>